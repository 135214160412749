.background {
  position: relative;
  padding-top: 54px;

  background-color: rgb(0, 0, 0);

  background-size: 100% 100%;
}

.background__image {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 54px;

  width: 100%;
  height: 1020px;
  background-image: url("../../images/bg-finish.webp");
  background-repeat: no-repeat;
  background-size: 100% 1020px;
}

.header {
  position: relative;

  margin-bottom: 103px;
}

.progress {
  margin-bottom: 51px;
}

.wallets {
  position: relative;

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: flex;

  list-style: none;
}

.wallet {
  margin-right: 10px;
}

.title {
  position: relative;

  display: flex;

  margin: 0;
  margin-bottom: 43px;
}

.icon-fire {
  font-size: 80px;
  line-height: 88px;
  margin-left: 10px;
}

.caption {
  position: relative;
  z-index: 2;

  margin: 0;
  margin-bottom: 33px;

  font-family: "Hauora_Regular", sans-serif;

  font-size: 24px;
  line-height: 33px;
  color: rgb(255, 255, 255);

  letter-spacing: 0.5px;
}

.buttons {
  position: relative;
  z-index: 2;

  margin: 0;
  margin-bottom: 276px;
  padding: 0;
  display: flex;

  list-style: none;
}

.button {
  margin-right: 17px;
}

.button:last-child {
  margin-right: 0;
  align-self: center;
}

.involved {
  margin-bottom: 100px;
}

.involved__title {
  margin-bottom: 61px;
}

.involved__list {
  max-width: 817px;
}

.conversation {
  margin-bottom: 136px;
}

.conversation__title {
  margin-bottom: 50px;
}

@media screen and (max-width: 1366px) {
  .icon-fire {
    font-size: 60px;
    line-height: 70px;
  }
}

@media screen and (max-width: 1275px) {
  .main {
    width: 100%;
  }

  .involved__list {
    max-width: 100%;
  }
}

@media screen and (max-width: 810px) {
  .main {
    padding: 0 15px;
  }

  .buttons {
    display: block;
    margin-bottom: 117px;
  }

  .button {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .button:last-child {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .icon-fire {
    font-size: 42px;
    line-height: 50px;
  }
}

@media screen and (max-width: 435px) {
  .wallets {
    display: block;
    margin-bottom: 15px;
    margin-top: 60px;
  }

  .wallet {
    margin-right: 0;
    margin-bottom: 10px;
    max-width: 200px;
  }

  .wallet:last-child {
    margin-bottom: 0;
  }

  .icon-fire {
    display: none;
  }

  .progress {
    position: absolute;
    right: 20px;
  }
}
