.container {
  display: flex;
  flex-direction: column;
}

.list {
  margin: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 15px;

  list-style-image: url("../../images/list-image.svg");
}

.list_social {
  margin: 0;
  padding: 0;
  display: flex;

  list-style: none;
}

.container_jc_end {
  justify-content: flex-end;
}
