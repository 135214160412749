.background {
  position: relative;
  padding-top: 54px;

  background-color: rgb(0, 0, 0);

  background-size: 100% 100%;
}

.background__image {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 54px;

  width: 100%;
  height: 1020px;
  background-image: url("../../images/bg-finish.webp");
  background-repeat: no-repeat;
  background-size: 100% 1020px;
}

.header {
  position: relative;
  /* z-index: 2; */

  margin-bottom: 149px;
}

.title {
  position: relative;
  /* z-index: 2; */

  max-width: 900px;
  margin: 0;
  margin-bottom: 37px;
}

.buttons {
  position: relative;
  /* z-index: 2; */

  margin: 0;
  margin-bottom: 240px;
  padding: 0;
  display: flex;

  list-style: none;
}

.button {
  margin-right: 17px;
}

.button:last-child {
  margin-right: 0;
  align-self: center;
}

.involved {
  margin-bottom: 100px;
}

.involved__title {
  margin-bottom: 61px;
}

.involved__list {
  max-width: 817px;
}

.conversation {
  margin-bottom: 136px;
}

.conversation__title {
  margin-bottom: 50px;
}

@media screen and (max-width: 1363px) {
  .involved__title {
    padding-top: 130px;
  }
}

@media screen and (max-width: 1024px) {
  .involved__title {
    padding-top: 135px;
  }
}

@media screen and (max-width: 1100px) {
  .main {
    padding: 0 40px;
  }
}

@media screen and (max-width: 900px) {
  .main {
    padding: 0 15px;
  }
}

@media screen and (max-width: 810px) {
  .buttons {
    display: block;
  }

  .button {
    margin-bottom: 10px;
  }

  .involved__title {
    padding-top: 0;
  }
}
