@import url(./vendors/fonts/fonts.css);

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1680px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

.main {
  width: 90.64%;
  padding: 0 90px;
}

@media screen and (max-width: 940px) {
  .container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1100px) {
  .main {
    width: 100%;
    padding: 0 40px;
  }
}

@media screen and (max-width: 700px) {
  .main {
    padding: 0 15px;
  }
}
