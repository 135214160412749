.area {
  width: 100%;
  padding: 14px;

  font-family: "Roboto_Regular" sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: rgb(0, 0, 0);

  background-color: rgba(196, 196, 196, 0.5);

  border-radius: 5px;
  overflow-y: scroll;
  resize: none;
}

.area::-webkit-scrollbar {
  width: 0px;
  background-color: rgba(196, 196, 196, 0.5);
}
.area::-webkit-scrollbar-thumb {
  background-color: rgb(196, 196, 196);
}
