.link-with-icon {
  display: flex;
  align-items: center;

  font-family: "Hauora_Bold", sans-serif;
  font-size: 19px;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);

  text-decoration: none;
  transition: opacity 0.2s linear 0.3s;
}

.link-with-icon_color_white {
  color: rgb(255, 255, 255);
}

.link-with-icon:hover {
  opacity: 0.6;
  cursor: pointer;
}

.link-with-icon__icon_color_white {
  background-image: url("../../images/dialog.svg");
  border-color: rgb(207, 255, 196);
}
