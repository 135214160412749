.nav__list {
  margin: 0;
  padding: 0;
  display: flex;

  list-style: none;
}

.burger {
  position: relative;

  width: 28px;
  height: 16px;
  padding: 0;

  background-color: inherit;

  border: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;

  width: 100%;
  height: calc(var(--vh) * 100);

  visibility: hidden;
  opacity: 0;
  background-color: #fff;

  overflow-y: hidden;
  transition: all 0.7s linear 0.2s;
}

.overlay_active {
  visibility: visible;
  opacity: 1;
}

.body {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50033;

  width: fit-content;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  background-color: rgb(255, 255, 255);
}

.close {
  position: absolute;
  top: 2rem;
  right: 3rem;
  width: 21px;
  height: 21px;
  background-color: transparent;
  border: none;
}

.close::before,
.close::after {
  content: "";
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: rgb(0, 0, 0);

  transition:
    background-color 0.5s,
    linear,
    0.5s;
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}

.close:hover {
  cursor: pointer;
}

.close:hover.close::before,
.close:hover.close::after,
.close:focus.close::before,
.close:focus.close::after {
  background-color: rgba(0, 0, 0, 0.6);
  outline: none;
}

.burger::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  width: 28px;
  height: 3px;

  background-color: rgb(255, 255, 255);
  box-shadow: 0 16px 0 0 rgb(255, 255, 255);
}

.burger__list {
  margin: 0;
  margin-bottom: 52px;
  padding: 0;

  list-style: none;
}

.burger__item {
  margin-bottom: 42px;
}

.burger__item:last-child {
  margin-bottom: 0;
}

.burger__link {
  font-family: "Hauora_Bold", sans-serif;
  font-size: 21px;
  line-height: 30px;
  color: rgb(0, 0, 0);

  letter-spacing: 0.6;
  text-decoration: none;
}

.burger__socials {
  display: flex;
  margin: 0;
  margin-top: 162px;
  padding: 0;

  list-style: none;
}

.burger__social {
  margin-right: 30px;
}

.burger__social:last-child {
  margin-right: 0;
}

.nav__link:hover {
  color: rgba(207, 255, 196, 0.7);
  text-decoration: underline;
  cursor: pointer;
}

.nav__item {
  margin-right: 40px;
}

.nav__item:last-child {
  margin-right: 0;
}

.nav__link {
  font-family: "Hauora_Bold", sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: rgb(255, 255, 255);

  letter-spacing: 0.2px;
  text-decoration: none;
  transition: all 0.2s linear 0.4s;
}

.nav__link:hover {
  color: rgba(207, 255, 196, 0.7);
  text-decoration: underline;
  cursor: pointer;
}

.nav__link_active {
  color: rgba(207, 255, 196, 1);
}

.nav__link_active:hover {
  color: rgba(207, 255, 196, 1);
  text-decoration: none;
  cursor: default;
}
